import TYPES from '@/types';
import Vue from 'vue';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetCustomerKuspitPositionQuery
  from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries/get-customer-kuspit-position-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import UpdateEmergencyFundInvestorGoalCommand
  from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-emergency-fund-investor-goal-command';

// Domain
import {
  FinalInvestmentDateCalculatorDto,
} from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';

import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  GoalsDashboardProgressStateManager,
} from '@/modules/goals-dashboard/progress/domain/state/goals-dashboard-progress-state-manager';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class EmergencyFundSummaryViewModel {
  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.UPDATE_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_emergency_fund_goal!: UpdateEmergencyFundInvestorGoalCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_PROGRESS_STATE_MANAGER)
  private readonly progress_state_manager!: GoalsDashboardProgressStateManager;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.emergency-fund.summary';

  readonly view: Vue;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  progress_state = this.progress_state_manager.state;

  kuspit_position = 0;

  balance = 0;

  has_active_goals = false;

  constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_continue_btn_disabled() {
    return this.investor_goal_state.is_loading;
  }

  items = [
    {
      id: 'linked_with',
      label: this.translate('linked_with'),
      value: 'SOWOS Pocket',
      tooltip: this.translate('pocket_tooltip'),
    },
    {
      id: 'desired_amount',
      label: this.translate('desired_amount'),
      value: `${currencyFormat(this.investor_goal_state.investor_goal.desired_amount)} MXN`,
    },
    {
      id: 'initial_amount',
      label: this.translate('initial_amount'),
      value: `${currencyFormat(this.investor_goal_state.investor_goal.initial_amount)} MXN`,
    },
    {
      id: 'monthly_contribution',
      label: this.translate('monthly_contribution'),
      value: `${currencyFormat(this.investor_goal_state.investor_goal.monthly_required_amount)} MXN`,
    },
    {
      id: 'target_date',
      label: this.translate('target_date'),
      value: '',
    },
  ];

  loadCustomerInvestorGoalBalance = async () => {
    try {
      if (this.progress_state.has_any_goal_linked_pocket) {
        const customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
          investment_product_id: this.investor_goal_state.associated_product_id,
          reload: true,
        };
        const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
          .execute(customer_investor_goal_balance);
        this.balance = unassigned_balance;
      } else {
        await this.loadKuspitPosition();
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('pocket.errors.get_customer_investor_goal_balance'));
      } else {
        await this.loadKuspitPosition();
      }
    }
  }

  loadKuspitPosition = async () => {
    try {
      const kuspit_position_data = await this.get_customer_kuspit_position_query.execute();
      if (kuspit_position_data.has_funds) {
        this.balance = parseFloat(kuspit_position_data.position);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('pocket.errors.get_kuspit_position'));
    }
  }

  loadFinalInvestmentDate = async (months_to_add: number) => {
    try {
      const initial_investment_date = this.datetime_value.create().toString();
      this.investor_goal_state.investor_goal.initial_investment_date = initial_investment_date;
      const payload: FinalInvestmentDateCalculatorDto = {
        initial_investment_date,
        period_in_months: months_to_add,
      };
      const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
      this.items[4].value = this.date_formatter
        .formatDate(final_investment_date, 'DD/MM/YYYY');
      this.investor_goal_state.investor_goal.final_investment_date = final_investment_date;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_final_investment_date_query'));
    }
  }

  setState = () => {
    this.investor_goal_state.available_balance = this.balance;
    this.investor_goal_state.investor_goal.goal_name = 'Fondo de Emergencia';
  }

  updateEmergencyFund = async () => {
    try {
      const payload = {
        id: this.investor_goal_state.investor_goal.id,
        investor_goal: {
          investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
          monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
          initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
          final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
        },
        desired_amount: this.investor_goal_state.investor_goal.desired_amount,
        fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted.toFixed(2),
      };
      await this.update_emergency_fund_goal.execute(payload);
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.create'));
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    try {
      this.setState();
      if (this.investor_goal_state.associated_product_contracted) {
        this.view.$emit('nextStep');
      } else {
        await this.updateEmergencyFund();
        this.investor_goal_state.goal_created = true;
        this.view.$emit('endProcess');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_goal'));
    }
  }

  initialize = async () => {
    if (this.investor_goal_state.associated_product_contracted) {
      await this.loadCustomerInvestorGoalBalance();
    }
    await this.loadFinalInvestmentDate(
      this.investor_goal_state.investor_goal.fixed_time_adjusted,
    );
  }
}
